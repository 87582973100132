/**
 * In LabelRight Application, all hard-coded strings are declared and managed in the “constants.ts” file using key-value pairs. 
 * This ensures a centralized and organized approach to handling static strings throughout the application. 
 *
 */

export const dbOrMyidm = {
    db: 'db',
    myidm: 'myidm'
}

//Choose the route from where response should flow - DB or MYIDM?
export const loginResponse = {
    loginResponseFrom : dbOrMyidm.myidm //Enable this to choose MYIDM route
    // loginResponseFrom: dbOrMyidm.db //Enable this to choose DB route
}

export const artworkStatus = {
    Completed: 'completed'
}
export const errorTypes = {
    alert: 'Alert',
    mismatch: 'MisMatch',
    errorOccured: 'Error Occured',
    userIgnored:'User Ignored',
    noFilter :'No Filter',
    match :'Match',
    noFilterWithoutSpace : 'NoFilter',
    error : 'Error',
    notFound : 'Not Found',
    undetected: 'Not Detected',
    mismatchAlert : 'MisMatchAlert',
    mismatchNotDetected : 'MisMatchNot Detected',
    alertNotDetected :'AlertNot Detected'
}
export const currentStatus = {
    Requested: 'Requested',
    ProjectCreated: 'Project Created',
    FilesMoved: 'File Moved',
    FilesReceived: 'File(s) Received',
    detectLanguage: 'detectLanguage',
    LanguageDetection: 'Language Detection',
    AnalysisInProgress: 'Analysis In Progress',
    ActivityScheduled: 'Activity Scheduled',
    InProgress: 'InProgress',
    ActivityInProgress: 'Activity InProgress',
    ActivityCompleted: 'Activity Completed',
    ProcessArt: 'processArt',
    ProcessLID: 'processLid',
    LIDExtraction: 'lidExtraction',
    LIDExtractionDesc: 'LID Extraction',
    GetBoudingBox: 'getBoundingBox',
    Generategreypdf: 'generateGreyPdf',
    getOCR: 'getOCR',
    getBBOverlay: 'getBBOverlay',
    getBBOverlayDesc: 'Boxes Overlay Generation',
    Resultsgen: 'Results Generated',
    Completed: 'Completed',
    ErrorOccurred: 'Error Occured',
    ArtworkProcessing: 'Artwork Processing',
    LIDProcessing: 'LID Processing',
    BoxesDetection: 'Boxes Detection',
    GreyPDFGeneration: 'Grey PDF Generation',
    ResultsGeneration: 'Results Generation',
    FileNotFound: 'FILE_NOT_FOUND',
    FileNotReceived: 'File(s) Not Received'
}
export const projectstatus = {
    ResultsGenQualified: "Results Generated Qualified",
    ResultsMoved: 'Results Moved',
    ResultsGenNotQualified: 'Results Generated Not Qualified',
    ResultsNotified: 'Results Notified',
    ResultsNotificationError: 'Results Notification Error',
    ResultsNotificationTimedout: 'Results Notification Timedout',
    UiResultsGenQualified: 'Report to AWH - Pending',
    UiResultsGenNotQualified: 'Report to AWH - Not Sent',
    UiResultsNotified: 'Report to AWH - Sent',
    UiResultsNotificationError: 'Report to AWH - Error',
    UiResultsNotificationTimedout: 'Report to AWH - Timed Out',
    completed: 'Completed'
}
export const filteringTypes = {
    Brand: 'Brand',
    User: 'User',
    Result: 'Results',
    currentStatus: 'currentStatus',
    ALL: 'All'
}
export const routeUrls = {
    getstarted: 'get-started',
    upload: 'upload',
    verify: 'verify',
    results: 'results',
    dashboard: 'dashboardpage',
    uploadqueued: 'queue',
    alldone: 'all-done',
    homepage: 'homepage',
    root: '/'
}

export const TimestampConstants = {
  expiryTimePeriodForVerify: 5000,
  expiryTimeForUpload: 3000,
  expiryTimePeriadForResult: 5000,
//   inactivityTimeout: 30 * 60 * 1000,
  inactivityTimeout: 5 * 60 * 60 * 1000,
  warningThreshold: 5 * 60 * 1000
};

export const fileKeyObject = {
    artFull: 'artFull',
    artMid:'artMid',
    artOriginal: 'artOriginal',
    language: 'language',
    lidPreview: 'lidPreview',
    lidOriginal: 'lidOriginal',
    artDetect: 'artDetect',
    bbAll: 'bbALL',
    newBbAll: 'new_bbALL',
    brRules: 'BRRules',
    resultsAll: 'resultsALL',
    newResultsAll: 'new_resultsALL',
    grayPdf: 'grayPDF',
    pdfXfd: 'PDF_XFD',
    overlayAll: 'overlayALL',
    huXfd: 'huxfd',
    originalPdfXfd: 'ORIGINAL_PDF_XFD',
    results : 'results',
    newBrRules:'new_BRRules',
    newOverlayAll:'new_overlayALL',
    newpdfXfd :'new_PDF_XFD'
}
export const keyArraysConstants ={
    uploadPageKeysArtwork :[fileKeyObject.artMid,fileKeyObject.artOriginal,fileKeyObject.language],
    uploadPageKeysLid:[fileKeyObject.lidPreview,fileKeyObject.lidOriginal],
    bbpgageKeys :[fileKeyObject.artDetect,fileKeyObject.bbAll],
    verifypageKeys:[fileKeyObject.brRules,fileKeyObject.resultsAll,fileKeyObject.grayPdf,fileKeyObject.pdfXfd,fileKeyObject.overlayAll]
}

export const blobStorageFolderStructure = {
    manualProjectFolder: 'Manual',
    batchProjectFolder: 'Batch',
    tempFolder: 'temp'
}

export const packageCategory = {
    varietyPack: 'Variety Pack',
    secondaryPack: 'Secondary',
    selectPackage: 'Select Package'
}

export const regionObject = {
    usRegion: 'US',
    chinaRegion: 'China',
    saudiRegion: 'Saudi Arabia',
    latamRegion: 'LATAM'
}

export const categoryAndModelCategory = {
    socialBeverages: 'Social Beverages',
    beverages: 'Beverages',
    gatorade: 'Gatorade Performance Portfolio',
    snacks: 'Snacks',
    foods: 'Foods',
    quaker: 'Quaker',
    sbeverage: 'SBEVERAGE',
    gbeverage: 'GPPBEVERAGE',
    nutrition: 'Nutrition'
}

export const userRoles = {
    labelRightUserRole : 'LabelRight User'
}

export const componentProductLanguage = {
    allProducts: 'All Products',
    allLanguages: 'All Languages',
    nonVarietyPackProduct: 'Common',
    allComponents: 'All Components'
}
export const messagesOnScreen = {
    preparingResultsLoader: 'Preparing results',
    navigatingLoader: 'navigating please wait',
    fetchingDataLoader: 'please wait,fetching the data',
    gettingRecordsLoader: 'Getting records please Wait....',
    generatingResultsLoader: 'Generating results',
    processingArtworkLoader: 'Processing Artwork',
    processingLidLoader: 'Processing LID',
    gettingFilesDataLoader: 'Getting Files Data...',
    uploadingLidFilesLoader: 'Uploading LID Files',
    loadingArtworkPartsLoader: 'Loading Artwork Parts',
    detectingLanguageLoader: 'Detecting Language',
    makingMagicLoader: 'Making Magic',
    comparingArtworkToLidLoader: 'Comparing Artwork to LID',
    detectingArtworkPartsLoader: 'Detecting Artwork Parts',
    uploadingFileLoader: 'Uploading file',
    uploadingArtworkLoader: 'Uploading Artwork',
    uploadingLidLoader: 'Uploading LID',
    processingToolLoader: 'Processing tool',
    addingProjectToQueueLoader: 'Adding Project to Queue',
    filesGettingGeneratedMessage: 'The files are still getting generated. Please try after a while...',
    xfdfAndReportReadyToBeSentToArtworkHouseMessage: 'The XFDF and Report files will be sent to the Artwork House shortly',
    paginationMessage: 'Showing Projects in the last 3 months',
    lidSubmittedMessage: 'LidFile submitted Successfully',
    selectProductAndLanguageMessage: 'Please select Product and Language...',
    selectProductMessage: 'Please select Product...',
    selectLanguageMessage: 'Please select Language...',
    somethingWentWrongMessage: 'Something went wrong, please try again...',
    dropArtworkMessage: 'Drop your artwork here or',
    dropFileMessage: 'Drop your file here (Excel, PDF) or',
    selectPackageCategoryMessage: 'Select the package category before uploading Artwork file or LID file',
    wrongFileUploadMessage: `Uh oh, looks like you tried to upload the wrong file type, let’s try this again.
    Please upload a PDF for the artwork and an excel or PDF file for the LID.`,
    problemCreatingprojectMessage: `There is a problem in creating project`,
    selectBoxToEditMessage: 'Click inside the box you want to edit',
    selectBoxToDeleteMessage: 'Click inside the box you want to delete',
    choosePackageCategoryMessage: 'Choose the package category and upload the LID along with the artwork you wish to validate',
    updateStatus: 'Updating status please Wait....'
}

export const sessionStorageObject = {
    oktaAppUrl: 'okta-app-url',
    name: 'name',
    geo: 'geo',
    allgeoCat: 'allgeoCat',
    projectId: 'projectId',
    projectName : 'projectName',
    packageCategory: 'packageCategory',
    packageTable :'packageDataTable',
    isMultiLingualArtwork : 'isMultiLingualArtwork'
}

export const fileType ={
    artwork : 'Artwork',
    lid : 'Lid'
}

export const allAPIs = {
    regionsCategoryPackageTypeMapping : '/user/ui/regionsCategoryPackageTypeMapping',
    userRegionCategoryMapping : '/user/ui/userRegionCategoryMapping',
    manualArtworkReviewRequest : '/artwork/ui/manualArtworkReviewRequest',
    generateSasToken : '/common/generateSasToken',
    updateArtworkPackage : '/artwork/ui/updateArtworkPackage',
    artworkReviewRequests : '/artwork/ui/artworkReviewRequests',
    artworkReviewFiles : '/artwork/ui/artworkReviewFiles',
    artworkOrchestrator : '/api/orchTrigger/artworkOrchestrator',
    lidOrchestrator : '/api/orchTrigger/lidOrchestrator',
    extractionOrchestrator : '/api/orchTrigger/extractionOrchestrator',
    comparisonOrchestrator : '/api/orchTrigger/comparisonOrchestrator',
    generateResultsFile : '/api/generateResultsFile',
    artworkpreviewOrch : '/api/batchTrigger/artworkpreviewOrch',
    lidpreviewOrch : '/api/batchTrigger/lidpreviewOrch',
    artworkReviewFilesBatchProcessing : '/artwork/ui/artworkReviewFilesBatchProcessing',
    artworkReviewFile : '/artwork/ui/artworkReviewFile',
    updateProjectStatusUrl: '/artwork/ui/updateStatusByProjectId'
}
export const toolTip = {
  undetected: 'The component is not detected in the Artwork',
  mismatch: 'The LID text and Artwork text dont match',
  match: 'The LID text and Artwork text match',
  alert: 'The LID text and Artwork text match, but needs review',
  userIgnored: 'A user has ignored this result'
};