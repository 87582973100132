<div class="dashboardtablemain">
  <div class="searchandpaginator">
    <div class="paginator">
      <mat-paginator #paginator [pageSize]="9" [length]="firstTabdata.length" (page) = "onPaginatorChange($event)"></mat-paginator>
    </div>
    <div class="go-to-page">
      <span class="paginator">Go to page </span>
      <mat-form-field appearance="outline" class="pageNumberBox">
        <input type="number" matInput placeholder="" [(ngModel)]="pageNumber" min="1" [max]="maxPageNumber"
          oninput="this.value = !!this.value && Math.abs(this.value) > 0 ? Math.abs(this.value) : null">
      </mat-form-field>
      <button class="go-arrow" (click)="goToPage()">
        Go<img class="go-arrow-icon" src="/assets/images/arrow_right.svg">
      </button>
    </div>

    <div class="search">
      <button class="searchbutton"><img src="../../../assets/images/search.svg" /></button>
      <input type = "text" class="inputsearch" (keyup)="applyFilter(false,$event)" placeholder="Search" [value]="filterSearchValue" #input>
      <button class="cancelButton" *ngIf="cancelIcon" (click)="clearsearchfilter()"><img
          src="../../../assets/images/cancel.svg" /></button>
    </div>
  </div>
  <div class="dashboardTable">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 dashboardtable">
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element;let i = index">{{(paginator.pageIndex * paginator.pageSize)+(i+1)}}</td>
      </ng-container>
      <ng-container matColumnDef="ArtworkInfo">
        <th mat-header-cell *matHeaderCellDef> Project <span class="smalltext"> (Click to view details)</span></th>
        <td mat-cell *matCellDef="let element;let i = index"
          class = "project-pointer">
          <span class="elementlidFile" matTooltip="{{element.projectName}}({{element.projectId}})"
            [matTooltipShowDelay]="1000" #tooltip="matTooltip" [matTooltipPosition]="'below'"
            (click)="openDialog(i)">
            {{element.projectName}} ({{element.projectId}}) </span>
          <span *ngIf="!(element.isAutomated) && !(element.isBatched)">
            <img src="../../../assets/images/powerGrey.svg" />
          </span>
          <span *ngIf="!(element.isAutomated) && (element.isBatched)">
            <img class="queue-icon" src="../../../assets/images/add-to-queue-small-gray.svg" />
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="ViewArtworkIcon" *ngIf="tabidentity==='inprogresstab'|| tabidentity==='Reviewdtab'">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell class="aw-lid-icon" *matCellDef="let element; let i = index">
          <span *ngFor="let DTOs of element.artworkReviewFilesDTOs">
            <span *ngIf="DTOs.fileKey === 'artMid' && DTOs.fileName">
              <span><img class="previewimg project-pointer" src="../../../assets/images/viewartwork1.svg"
                  (click)="previewArtwork(i)" /></span>
            </span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="cycle" *ngIf="tabidentity==='inprogresstab'|| tabidentity==='Reviewdtab'">
        <th mat-header-cell *matHeaderCellDef> Cycle </th>
        <td mat-cell *matCellDef="let element">{{ element.artworkReviewCycle}} </td>
      </ng-container>
      <ng-container matColumnDef="ArtworkHouse">
        <th mat-header-cell *matHeaderCellDef> Brand
          <span class="filterIcon" (click)="columlevelFilter('brand')">
            <img *ngIf="!filterIconBrandActive" src="../../../assets/images/filterListIcon.svg" />
            <img *ngIf="filterIconBrandActive" src="../../../assets/images/activeFilterIcon.svg" />
          </span>
          <span *ngIf="filterBrandItems">
            <app-filter-list [CheckboxValues]="uniqueArrayBrand" [filterType]="'Brand'"
              (selectedData)="selectedFilter($event)" (cancelfilter)="hideFilter($event)"
              [selectedBoxes]="BrandSelected"></app-filter-list>
          </span>
        </th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.brandName}}" [matTooltipShowDelay]="1000"
          #tooltip3="matTooltip" [matTooltipPosition]="'below'"> <span class="minimumchar">{{element.brandName}} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Project">
        <th mat-header-cell *matHeaderCellDef> User
          <span class="filterIconUser" (click)="columlevelFilter('user')">
            <img *ngIf="!filterIconUserActive" src="../../../assets/images/filterListIcon.svg" />
            <img *ngIf="filterIconUserActive" src="../../../assets/images/activeFilterIcon.svg" />
          </span>
          <span *ngIf="filterUserItems">
            <app-filter-list [CheckboxValues]="uniqueArrayUser" [filterType]="'User'"
              (selectedData)="selectedFilter($event)" (cancelfilter)="hideFilter($event)"
              [selectedBoxes]="userSelected"></app-filter-list>
          </span>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="minimumchar" matTooltip="{{element.firstName}}({{element.createdBy}})"
            [matTooltipShowDelay]="1000" #tooltip2="matTooltip"
            [matTooltipPosition]="'below'">{{element.firstName}}({{element.createdBy}}) </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="submittedon" *ngIf="tabidentity==='inprogresstab'|| tabidentity==='Reviewdtab'">
        <th mat-header-cell *matHeaderCellDef> Submitted On </th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.createdDate}}" [matTooltipShowDelay]="1000"
          #tooltip1="matTooltip" [matTooltipPosition]="'below'">
          {{ element.createdDate | date:'yyyy-MM-dd'}} </td>
      </ng-container>
      <ng-container matColumnDef="lidfilename" *ngIf="tabidentity==='inprogresstab'|| tabidentity==='Reviewdtab'">
        <th mat-header-cell *matHeaderCellDef> LID File Name</th>
        <td mat-cell *matCellDef="let element;let i = index ">
          <span class="elementlidFile minchar " *ngFor="let DTOs of element.artworkReviewFilesDTOs">
            <span *ngIf="DTOs.fileKey === 'lidOriginal'" matTooltip="{{DTOs.fileName}}" [matTooltipShowDelay]="1000"
              #tooltip1="matTooltip" [matTooltipPosition]="'below'">{{DTOs.fileName}}</span></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="lidIcon" *ngIf="(tabidentity==='inprogresstab'|| tabidentity==='Reviewdtab')">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell class="aw-lid-icon" *matCellDef="let element; let i = index">
          <span *ngFor="let DTOs of element.artworkReviewFilesDTOs">
            <span *ngIf="DTOs.fileKey === 'lidPreview' && DTOs.fileName">
              <span><img class="previewimg project-pointer" src="../../../assets/images/previewlid.svg"
                  (click)="previewLidReviewed(i)" /></span>
            </span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="currentStatus" *ngIf="tabidentity==='inprogresstab'">
        <th mat-header-cell *matHeaderCellDef>Current Status
          <span class="filterIcon" (click)="columlevelFilter('currentStatus')">
            <img *ngIf="!filterIconCurrentStatusActive" src="../../../assets/images/filterListIcon.svg" />
            <img *ngIf="filterIconCurrentStatusActive" src="../../../assets/images/activeFilterIcon.svg" />
          </span>
          <span *ngIf="filterCurrentStatus">
            <app-filter-list [CheckboxValues]="uniquearrayCurrentStatus" [filterType]="'currentStatus'"
              (selectedData)="selectedFilter($event)" (cancelfilter)="hideFilter($event)"
              [selectedBoxes]="currentStatusSelected"></app-filter-list>
          </span>
        </th>
        <td mat-cell *matCellDef="let element"><span class="minimumcharStatus" matTooltip="{{reusableDasboardService.getCurrentStatus(element.status,element.statusDescription)}}" [matTooltipShowDelay]="1000"
        #tooltip3="matTooltip" [matTooltipPosition]="'below'"> 
          {{reusableDasboardService.getCurrentStatus(element.status,element.statusDescription)}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="resume" *ngIf="tabidentity==='inprogresstab'">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element;let i = index ">
          <button *ngIf="!element.isAutomated && !element.isBatched" mat-flat-button color="warn" class="resumeimg project-pointer"
            (click)="resumeFlow(element.projectId,element.projectName,element.selectedPackage,element.packageCategory,element.createdBy,element.isMultiLingualArtwork)"><span>
              <img src="../../../assets/images/resume.svg" /></span> Resume</button>

          <button *ngIf="element.status === errorOccured && (element.isAutomated || element.isBatched)" mat-flat-button color="warn" class="resumeimg project-pointer"
            (click)="resetStatus(element.projectId)"><span>
              <img src="../../../assets/images/resume.svg" /></span> Reset Status</button>
        </td>
      </ng-container>
      <ng-container matColumnDef="result" *ngIf="tabidentity==='Reviewdtab'">
        <th mat-header-cell *matHeaderCellDef> Result <span class="smalltext"> (Click to view details)</span>
          <span class="filterIconUser" (click)="columlevelFilter('result')">
            <img *ngIf="!filterIconResultActive" src="../../../assets/images/filterListIcon.svg" />
            <img *ngIf="filterIconResultActive" src="../../../assets/images/activeFilterIcon.svg" />
          </span>
          <span *ngIf="filterResultItems">
            <app-filter-list [CheckboxValues]="uniquearrayResult" [filterType]="'Results'"
              (selectedData)="selectedFilter($event)" (cancelfilter)="hideFilter($event)"
              [selectedBoxes]="resultSelected"></app-filter-list>
          </span>
        </th>
        <td mat-cell *matCellDef="let element" (click)="navigateToResultPage(element)" class="resultCount">
          <span [ngStyle]="{'color':element.isAutomated 
                                 && element.status === resultNotQualified ? 'red': element.isAutomated 
                                 && ( element.status === resultError || element.status === resultErrorTimedOut) ? '#f39c12':''}">
            <span *ngIf="element.errorCount > 0;else noerrorcount"> {{element.errorCount }} Errors </span>
            <ng-template #noerrorcount>No Errors</ng-template>
            <span *ngIf="element.isAutomated">| {{reusableDasboardService.getMappedStatus(element.status)}}</span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="downloadIcon" *ngIf="tabidentity==='Reviewdtab'">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element, let i = index" class="download">
          <span>
            <img class="project-pointer" src="../../../assets/images/download.svg" (click)="downloadxfdData(i)" />
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="awhUrlSentIcon" *ngIf="tabidentity==='Reviewdtab'">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element, let i = index" class="download">
          <span *ngIf="element.isAutomated && element.status === awhUrl && element.reviewArtworkUrl !== null && element.reviewArtworkUrl !== ''">
            <img class="link-image project-pointer" src="../../../assets/images/link-icon.svg"
              (click)="awhUrlSent(element.reviewArtworkUrl)" />
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="empty">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element" class="empty-cell"> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <footer class="footerwoordings">
    <span style="margin: 0px 15px 0px 0px;">
      <img src="../../../assets/images/viewartwork.svg" /><span class="names"> View ArtWork</span></span>
    <img src="../../../assets/images/preview.svg" /><span class="names"> View LID</span>
    <span *ngIf="tabidentity==='Reviewdtab'"> <img style="margin: 0px 0px 0px 15px;"
        src="../../../assets/images/downloadgrey.svg" /><span class="names"> Download Results</span> </span>
  </footer>
</div>
<app-window id="lid-window">
  <div class="lid-display-container">
    <div class="row divlid" style="float: right">
      <button class="btn" (click)="lidzoomIn()">
        <i class="fas fa-search-plus"></i>
      </button>
      <button class="btn" (click)="lidzoomOut()">
        <i class="fas fa-search-minus"></i>
      </button>
    </div>

    <div>
      <img #modalImg [ngStyle]="{ height: getheightChineselid() }" class="test-image" [src]="lidPreview" alt="" />
    </div>
  </div>
</app-window>

<app-window id="artwork-window">
  <div class="lid-display-container">
    <div class="row divlid" style="float: right">
      <button class="btn" (click)="lidzoomOut()"  [disabled] = "valuezoom<=min">
        <i class="fas fa-search-minus"></i>
      </button>
      <input type="range" [min]="min" [max] ="max" [value]="valuezoom" id="zoom-slider"
      (input)="zoomChanged($event)" style ="cursor: pointer;">
      <button class="btn" (click)="lidzoomIn()"   [disabled] = "valuezoom>=max">
        <i class="fas fa-search-plus"></i>
      </button>
    </div> 

    <div>
      <img #modalImg [ngStyle]="{ height: getheightChineseArtwork()}" class="test-image" [src]="artworkPreview"
        alt="" />
    </div>
  </div>
</app-window>

<app-window id="lid-reviewed-window">
  <div class="lid-display-container">
    <div class="row divlid" style="float: right">
      <button class="btn" (click)="zoomOutLid()" [disabled] = "valuelidzoom<=50">
        <i class="fas fa-search-minus"></i>
      </button>
      <input type="range"  min="50" max="1500" [value]="valuelidzoom" id="zoom-slider"
      (input)="zoomChangedLID($event)"  step = "5" style ="cursor: pointer;">
      <button class="btn" (click)="zoomInLid()" [disabled] = "valuelidzoom>=1500">
        <i class="fas fa-search-plus"></i>
      </button>
    </div>

    <div>
      <img #lidImg [ngStyle]="{ 'height': lidheight, 'width': lidwidth  }" class="test-image" [src]="lidReviewedPreview"
        alt="" />
    </div>
  </div>
</app-window>