/**
 * The “dashboardtable” component handles the display of projects and their details. 
 * It takes care of the column level filter, global search, jumping between pages containing list of projects, resume flow.
 *  
 */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ModelpopupComponent } from 'src/app/modelpopup/modelpopup.component';
import { lidList } from 'src/app/models/lidList';
import { HomepagedataService } from 'src/app/services/homepagedata.service';
import { LabelRightService } from 'src/app/services/label-right.service';
import { WindowService } from 'src/app/utils/window/window.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ResultsPageDataService } from 'src/app/services/results-page-data.service';
import { PageLoadingService } from 'src/app/utils/page-loading/page-loading.service';
import { currentStatus, errorTypes, fileKeyObject, filteringTypes, messagesOnScreen, projectstatus, routeUrls, sessionStorageObject } from 'src/app/utils/constants';
import { DashboardReusableService } from 'src/app/services/dashboard-reusable.service';
import { AppInsightsService} from 'src/app/services/app-insights.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dashboardtable',
  templateUrl: './dashboardtable.component.html',
  styleUrls: ['./dashboardtable.component.css']
})
export class DashboardtableComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('input') searchInput: ElementRef;
  @Input() tabidentity: string;
  @Input('firstTabdata') firstTabdata: lidList[];
  @ViewChild('lidImg', { static: false }) lidImg: ElementRef;
  @Output() statusUpdated = new EventEmitter<any>();
  public displayedColumns: string[];
  public dataSource = new MatTableDataSource<any>([]);
  public cancelIcon = false;
  public filename: string;
  public length: number;
  public submitButton = {};
  public lidPreview = '';
  public artworkPreview = '';
  public lidReviewedPreview = '';
  public AdhocProcess: boolean;
  public lidRequestIdForSubmit = '';
  public submittingState = {};
  public sortedData: lidList[];
  public sortedData1:lidList[];
  HumanAnnotationurl: string;
  isLidAvailable = false;
  isResultsAvailable = false;
  isBBAvailable = false;
  isArtfullAvailable =false;
  isNewBBAvailable = false;
  isOverlayallAvailable=false;
  isPdf_xfdAvailable = false;
  isGreypdfAvailable =false;
  lidheight: any;
  lidwidth: any;
  public resultNotQualified = projectstatus.ResultsGenNotQualified;
  public resultError = projectstatus.ResultsNotificationError
  public resultErrorTimedOut =projectstatus.ResultsNotificationTimedout
  public awhUrl = projectstatus.ResultsNotified;
  public errorOccured = errorTypes.errorOccured;
  pageNumber: number;
  maxPageNumber: number;
  public uniqueArrayBrand = [];
  public uniqueArrayUser = [];
  public uniquearrayResult = [];
  public uniquearrayCurrentStatus=[];
  public filterResultItems = false;
  public filterBrandItems = false;
  public filterUserItems = false;
  public filterCurrentStatus = false;
  public BrandSelected = [];
  public userSelected = [];
  public resultSelected = [];
  public currentStatusSelected = [];
  public filterIconBrandActive = false;
  public filterIconUserActive = false;
  public filterIconResultActive = false;
  public filterIconCurrentStatusActive = false;
  public filterRelatedArray = [];
  min=50;
  max=500;
  valuezoom=100;
  valuelidzoom=75;
  filterpageNumber;
  filterSearchValue='';

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private labelRightService: LabelRightService,
    public windowService: WindowService,
    private homepageService: HomepagedataService,
    private snackBar: MatSnackBar,
    private resultsPageDataService: ResultsPageDataService,
    private pageLoadingService: PageLoadingService,
    public reusableDasboardService: DashboardReusableService,
    private AppInsightsService:AppInsightsService                                                                                                                                                          
  ) { 
    this.router.routeReuseStrategy.shouldReuseRoute=()=>{
      return false;
  };
  }
  ngOnInit(): void {
      if(this.reusableDasboardService.isReviewed)
      {
        this.tabidentity = this.reusableDasboardService.isReviewed;
      }
    this.maxPageNumber =
      this.firstTabdata.length % 9 === 0
        ? this.firstTabdata.length / 9
        : Math.trunc(this.firstTabdata.length / 9) + 1;
        
    if (this.tabidentity === 'inprogresstab') {
      this.displayedColumns = [
        'index',
        'ArtworkInfo',
        'ViewArtworkIcon',
        'cycle',
        'ArtworkHouse',
        'Project',
        'submittedon',
        'lidfilename',
        'lidIcon',
        'currentStatus',
        'resume',
        'empty'
      ];
      this.AdhocProcess = true;
      let data = [...this.sortedData];
      data = this.sortedData.map((e)=>{
        const currentStatus  = this.reusableDasboardService.getCurrentStatus(e.status,e.statusDescription)?this.reusableDasboardService.getCurrentStatus(e.status,e.statusDescription):'';
        return{...e,currentStatus};
      });
      this.sortedData1= data;
      this.sortedData = this.firstTabdata.sort((a, b) => {
        return (
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        );
      });
      this.paginator._intl.itemsPerPageLabel =
        messagesOnScreen.paginationMessage;
    } else {
      this.displayedColumns = [
        'index',
        'ArtworkInfo',
        'ViewArtworkIcon',
        'cycle',
        'ArtworkHouse',
        'Project',
        'submittedon',
        'lidfilename',
        'lidIcon',
        'result',
        'downloadIcon',
        'awhUrlSentIcon',
        'empty'
      ];
      this.AdhocProcess = true;
      this.sortedData = this.firstTabdata.sort((a, b) => {
        return (
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        );
      });
      this.paginator._intl.itemsPerPageLabel =
        messagesOnScreen.paginationMessage;
    }
    this.dataSource.data = this.sortedData ? this.sortedData : [];
    this.filterRelatedArray = this.sortedData;
    this.length = this.dataSource.data.length;

    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    
     if(this.reusableDasboardService.dashboardPageNo)
     {
      this.pageNumber = Number(this.reusableDasboardService.dashboardPageNo); 
      this.goToPage(true);
     }
     if(this.reusableDasboardService.filterType)
     {
      this.selectedFilter(this.reusableDasboardService.filterType,true);
     }
     if(this.reusableDasboardService.filterSearch)
     {
      this.applyFilter(true);
     }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.tabidentity === 'inprogresstab') {
      this.sortedData = this.firstTabdata.sort((a, b) => {
        return (
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        );
      });
    } else if (changes && this.tabidentity === 'Reviewdtab') {
      this.sortedData = this.firstTabdata.sort((a, b) => {
        return (
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        );
      });
    }
    if(!this.filterpageNumber)
    {
    this.dataSource.data = this.sortedData ? this.sortedData : [];
    this.filterRelatedArray = this.sortedData;
    this.length = this.dataSource.data.length;
    }
  }
  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }
  applyFilter(filterSearch?:Boolean,event?: Event,) {
   
    const filterValue = filterSearch ? this.reusableDasboardService.filterSearch :(event.target as HTMLInputElement).value;
    this.reusableDasboardService.filterSearch = filterValue;
    this.filterSearchValue = filterSearch?this.reusableDasboardService.filterSearch :(event.target as HTMLInputElement).value?(event.target as HTMLInputElement).value:'';
    
    
    if (filterValue === '') {
      this.cancelIcon = false;
    } else if (filterValue !== '') {
      this.cancelIcon = true;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  clearsearchfilter(): void {
    this.dataSource.filter = '';
    this.searchInput.nativeElement.value = '';
    this.cancelIcon = false;
    this.reusableDasboardService.filterSearch=null;
    this.reusableDasboardService.dashboardPageNo=null;
    this.reusableDasboardService.filterType=null;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  openDialog(index) {
    const Index = this.calculatingRealIndexofTable(index);
    this.dialog.open(ModelpopupComponent, {
      data: this.dataSource.filteredData[Index]
    });
  }
  previewArtwork(index) {
    const Index = this.calculatingRealIndexofTable(index);
    this.valuezoom=100;
    let previewArtworkfileName = '';
    this.dataSource.filteredData[Index].artworkReviewFilesDTOs.forEach((e) => {
      if (e.fileKey === fileKeyObject.artMid) {
        previewArtworkfileName = e.blobUrl;
      }
    });
    if (previewArtworkfileName) {
      this.artworkPreview = previewArtworkfileName;
      this.windowService.open('artwork-window');
    }
  }
  previewLidReviewed(index) {
    this.valuelidzoom=100;
    const Index = this.calculatingRealIndexofTable(index);
    let previewLidfileName = '';
    this.dataSource.filteredData[Index].artworkReviewFilesDTOs.forEach((e) => {
      if (e.fileKey === fileKeyObject.lidPreview) {
        previewLidfileName = e.blobUrl;
      }
    });
    if (previewLidfileName) {
      this.lidReviewedPreview = previewLidfileName;
      this.windowService.open('lid-reviewed-window');
      this.lidheight = 'auto';
      this.lidwidth = '100%';
      this.lidzoom = false;
      this.firstzoom = false;
    }
  }
  // for test
  // lidzoom: boolean = false;
  lidzoom: boolean;
  lidzoomvalue = 170;
  firstzoom = false;
  zoomChangedLID(event)
  {
  const zoomVal =  parseInt(event.target.value) - this.valuelidzoom;
  if(this.valuelidzoom>event.target.value)
  {
      this.zoomOutLid("out",zoomVal);
  }
  else
  {
      this.zoomInLid("in",zoomVal);
  }
      
  this.valuelidzoom=parseInt(event.target.value);
  }

  zoomInLid(val?:string,zoomVal?:number) {
    this.lidzoom = true;
    zoomVal=zoomVal?zoomVal:0;
    const image = new Image();
    image.src = this.lidImg.nativeElement.src;
    image.onload = () => {
      if (this.lidImg) {
        let imgHeight = (this.lidImg.nativeElement as HTMLImageElement)
          .offsetHeight;
        let imgWidth = (this.lidImg.nativeElement as HTMLImageElement)
          .offsetWidth;
        this.lidheight = imgHeight + 10 +zoomVal;
        this.lidwidth = imgWidth + 10+zoomVal;
        this.lidheight = this.getheightChineselid();
        this.lidwidth = this.getwidthChineselid();
      }
    };
    if(!val)
    this.valuelidzoom+=10;
  }
  zoomOutLid(val?:string,zoomVal?:number) {
    this.lidzoom = true;
    zoomVal=zoomVal?zoomVal:0;
    const image = new Image();
    image.src = this.lidImg.nativeElement.src;
    image.onload = () => {
      if (this.lidImg) {
        let imgHeight = (this.lidImg.nativeElement as HTMLImageElement)
          .offsetHeight;
        let imgWidth = (this.lidImg.nativeElement as HTMLImageElement)
          .offsetWidth;
        if(imgHeight> 500 && imgWidth > 500)
        {
        this.lidheight = imgHeight - 10 + zoomVal;
        this.lidwidth = imgWidth - 10 + zoomVal;
        }
        this.lidheight = this.getheightChineselid();
        this.lidwidth = this.getwidthChineselid();
      }
    };
    if(!val)
    this.valuelidzoom-=10;
  }
  getheightlid() {
    if (this.lidzoom == true) {
      return this.lidzoomvalue + '%';
    } else {
      return '170%';
    }
  }
  artworkzoomvaluechinese = 100;
  zoomChanged(event)
  {
    const zoomVal =  parseInt(event.target.value) - this.valuezoom;
    this.valuezoom>parseInt(event.target.value)?this.lidzoomOut("in",zoomVal):this.lidzoomIn("out",zoomVal);
    this.valuezoom=parseInt(event.target.value);
  }
  lidzoomOut(val?:string,zoomVal?:number) {
    zoomVal = zoomVal?zoomVal:0; 
    if(this.artworkzoomvaluechinese >=100)
    {
    this.lidzoomvaluechinese = this.lidzoomvaluechinese - 5 + zoomVal;
    this.artworkzoomvaluechinese = this.artworkzoomvaluechinese - 5 + zoomVal;
    this.artworkzoomvaluechinesewidth = this.artworkzoomvaluechinesewidth - 5 + zoomVal;
    }
    if(!val)
    {
    this.valuezoom=this.valuezoom-10 ;
    }
  }
  
  lidzoomIn(val?:string,zoomVal?:number) {
    this.lidzoom = true;
    zoomVal=zoomVal?zoomVal:0;
    this.artworkzoomvaluechinese = this.artworkzoomvaluechinese + 5 + zoomVal;//height
    this.lidzoomvaluechinese = this.lidzoomvaluechinese + 5 + zoomVal;//common
    this.artworkzoomvaluechinesewidth = this.artworkzoomvaluechinesewidth + 5 + zoomVal;//width
    if(!val)
    {
    this.valuezoom=this.valuezoom+10;
    }
  }
  getheightChineseArtwork() {
    if (this.lidzoom == true) {
      return this.artworkzoomvaluechinese + '%';
    } else {
      return '100%';
    }
  }
  lidzoomvaluechinese = this.valuezoom;

  artworkzoomvaluechinesewidth = 100;
  getheightChineselid() {
    if (this.lidzoom == true) {
      return this.lidheight + 'px';
    }
  }
  getwidthChineselid() {
    if (this.lidzoom == true) {
      return this.lidwidth + 'px';
    }
  }

  
  calculatingRealIndexofTable(index) {
    return this.paginator.pageIndex * this.paginator.pageSize + index;
  }

  resetFields() {
    this.labelRightService.pdfFileUrl = '';
    this.labelRightService.xdfData = '';
    this.labelRightService.xfd_Data.next('');
    this.labelRightService.graypdfURL = '';
    this.resultsPageDataService.xfdDataForAWView ='';
    this.resultsPageDataService.original_Xfd ='';
    this.labelRightService.annotationdata = [];
    this.labelRightService.resetArt();
    this.labelRightService.resetLID();
    this.labelRightService.resetResults();
    this.labelRightService.resetBRRules();
    this.labelRightService.resetPDF_XFD();
    this.labelRightService.bbAllImageURL.next('');
    this.homepageService.resultPreviousButton = true;
    this.homepageService.alldoneSentAWHButton = false;
    this.homepageService.clearSessionValue();
  }

  navigateToResultPage(result) {
    this.resetFields();
    sessionStorage.setItem(sessionStorageObject.projectName,result.projectName)
    this.resultsPageDataService.initialisingAllData(
      result.artworkReviewRequestId
    );
    this.resultsPageDataService.dataMappingforResultPageNavigation(result)
    sessionStorage.setItem(sessionStorageObject.projectId, result.artworkReviewRequestId);
    sessionStorage.setItem(sessionStorageObject.isMultiLingualArtwork, JSON.stringify(result.isMultiLingualArtwork ? result.isMultiLingualArtwork : false));
    this.router.navigate([routeUrls.results]);
  }

  downloadxfdData(index: any) {
    let xlsxUrl = '';
    let xfdUrl = '';
    let pdfUrl = '';
    let realIndex = this.calculatingRealIndexofTable(index);
    this.dataSource.filteredData[realIndex].artworkReviewFilesDTOs.forEach((e) => {
      if (e.fileKey === fileKeyObject.results) {
        xlsxUrl = e.blobUrl;
      } else if (e.fileKey === fileKeyObject.huXfd) {
        xfdUrl = e.blobUrl;
      } else if (e.fileKey === fileKeyObject.artOriginal) {
        pdfUrl = e.blobUrl;
      }
    });
    window.location.replace(xlsxUrl);
    setTimeout(() => {
      window.location.replace(xfdUrl);
    }, 2000);
    setTimeout(() => {
      window.location.replace(pdfUrl);
    }, 4000);
    this.AppInsightsService.logEvent("Downloded XFDF/PDF & Report Data - Dashboard",
    {
      xfdf:xfdUrl,
      pdfUrl:pdfUrl,
      report:xlsxUrl,
    })
  }

  /**
   * "Resume" button is provided, allowing users to return to the last page of incomplete transactions. 
   * Resume Logic for incomplete transactions: 
   * Resume from Results screen – If available file keys are "resultsALL”, "new_bbALL", ”overlayALL”, “PDF_XFD” and “grayPDF”. 
   * Resume from Verify screen – If available file keys are "artFull" and "bbALL". 
   * Resume from Upload screen – For other cases. 
   * @param projectId 
   * @param projectName 
   * @param selectedPackage 
   * @param packageCategory 
   * @param createdBy 
   * @param isMultiLingualArtwork 
   * 
   */
  
  resumeFlow(projectId, projectName, selectedPackage, packageCategory, createdBy, isMultiLingualArtwork) {
    this.homepageService.navigatingFromDashboard = true;
    this.resetFields();
    this.pageLoadingService.show(messagesOnScreen.navigatingLoader);
    this.resultsPageDataService.artworkAnalysisId = projectId;
    this.homepageService.projectAuther = createdBy;
    this.resultsPageDataService.savedProjectName = projectName;
    sessionStorage.setItem(sessionStorageObject.packageCategory, (selectedPackage ? selectedPackage : packageCategory));
    sessionStorage.setItem(sessionStorageObject.projectId, projectId);
    sessionStorage.setItem(sessionStorageObject.projectName, projectName);
    sessionStorage.setItem(sessionStorageObject.isMultiLingualArtwork, JSON.stringify(isMultiLingualArtwork ? isMultiLingualArtwork : false));
    this.isBBAvailable = false;
    this.isLidAvailable = false;
    this.isResultsAvailable = false;
    this.isArtfullAvailable =false;
    this.isNewBBAvailable = false;
    this.isOverlayallAvailable=false;
    this.isPdf_xfdAvailable = false;
    this.isGreypdfAvailable =false;
    this.resultsPageDataService
      .gettingResultsData(projectId)
      .subscribe((data) => {
    this.resultsPageDataService.assaigningData(data);
       data.forEach((element) => {
          if (element.fileKey === fileKeyObject.lidOriginal) {
            this.isLidAvailable = true;
          }
          if (element.fileKey === fileKeyObject.bbAll) {
            this.isBBAvailable = true;
          }
          if (element.fileKey === fileKeyObject.resultsAll) {
            this.isResultsAvailable = true;
          }
          if(element.fileKey === fileKeyObject.newBbAll){
           this.isNewBBAvailable = true;
          }
          if(element.fileKey ===  fileKeyObject.overlayAll){
            this.isOverlayallAvailable = true;
          }
          if(element.fileKey === fileKeyObject.pdfXfd){
           this.isPdf_xfdAvailable = true;
          }
          if(element.fileKey === fileKeyObject.grayPdf){
            this.isGreypdfAvailable = true;
          }
          if(element.fileKey === fileKeyObject.artFull){
            this.isArtfullAvailable = true;
          }
        });
        if (
          this.isResultsAvailable &&
          this.isNewBBAvailable &&
          this.isOverlayallAvailable &&
          this.isPdf_xfdAvailable &&
          this.isGreypdfAvailable
        ) {
          this.router.navigate([routeUrls.results]);
        } else if (this.isArtfullAvailable && this.isBBAvailable) {
          this.router.navigate([routeUrls.verify]);
        }else{
          this.router.navigate([routeUrls.upload]);
        }
      });
  }

  /**
   * "Reset Status" button is provided, allowing users to reset status to "Requested". 
   * @param projectId
   * 
   */
  
  resetStatus(projectId) {
    this.pageLoadingService.show(messagesOnScreen.updateStatus);
    this.homepageService
      .updateStatus(projectId, currentStatus.Requested)
      .subscribe((data) => {
        if (data.status === 200) {
          const category = JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.category;
          const region = JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.region
          this.statusUpdated.emit({
            region,
            category
          });
        }
      });
  }
  onPaginatorChange(event)
  {
    let pagenumber:number = Number(event.pageIndex+1);
    this.reusableDasboardService.dashboardPageNo = pagenumber;
  }
  goToPage(fromRouteParams?:Boolean) {
    this.reusableDasboardService.dashboardPageNo= this.pageNumber;
    if (this.pageNumber > this.maxPageNumber) {
      this.pageNumber = this.maxPageNumber;
    }
    this.paginator.pageIndex = this.pageNumber - 1;
    this.paginator.page.next({
      pageIndex: this.pageNumber - 1,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    });
    if(fromRouteParams)
    {
    this.pageNumber=null;
    }
  }

  columlevelFilter(type) {
    if (type == 'brand') {
      this.uniqueArrayBrand = [
        ...new Set(this.firstTabdata.map((item) => item.brandName))
      ];
      this.uniqueArrayBrand = this.uniqueArrayBrand
        .filter((s) => s != null)
        .sort((a, b) => (b > a ? -1 : 1));
      this.filterBrandItems = !this.filterBrandItems;
      this.filterUserItems = false;
      this.filterResultItems = false;
      this.filterCurrentStatus=false;
    } else if (type == 'user') {
      this.uniqueArrayUser = [
        ...new Set(this.firstTabdata.map((item) => item.firstName))
      ];
      this.uniqueArrayUser = this.uniqueArrayUser
        .filter((s) => s != null)
        .sort((a, b) => (b > a ? -1 : 1));
      this.filterUserItems = !this.filterUserItems;
      this.filterBrandItems = false;
      this.filterResultItems = false;
      this.filterCurrentStatus=false;
    } else if (type == 'result') {
      this.uniquearrayResult = [
        ...new Set(this.firstTabdata.map((item) => item.status))
      ];
      this.uniquearrayResult = this.uniquearrayResult
        .filter((s) => s != null)
        .sort((a, b) => (b > a ? -1 : 1));
      this.uniquearrayResult = this.uniquearrayResult.map((val) =>
        val.replace(
          projectstatus.ResultsMoved,
          projectstatus.ResultsGenQualified
        )
      );
      this.uniquearrayResult = this.uniquearrayResult.filter(
        (item, index, self) => self.indexOf(item) === index
      );
      this.filterResultItems = !this.filterResultItems;
      this.filterBrandItems = false;
      this.filterUserItems = false;
      this.filterCurrentStatus=false;
    }
    else if(type == 'currentStatus')
    {
      this.uniquearrayCurrentStatus = [
        ...new Set(this.sortedData1.map((item) => item.currentStatus))
      ];
      this.uniquearrayCurrentStatus = this.uniquearrayCurrentStatus
        .filter((s) => s != null)
        .sort((a, b) => (b > a ? -1 : 1));
      this.filterBrandItems = false;
      this.filterUserItems = false;
      this.filterResultItems = false;
      this.filterCurrentStatus=!this.filterCurrentStatus;
    }
  }
  selectedFilter(value,fromRoute?:Boolean) {
    this.reusableDasboardService.filterType = value; 
    if (value.filterselected === filteringTypes.Brand) {
      this.filterIconBrandActive = value.data.length == 0 ? false : true;
      this.filterIconResultActive = false;
      this.filterIconUserActive = false;
      this.BrandSelected = value.data;
      this.filterRelatedArray =
        value.data.length == 0
          ? this.sortedData
          : this.sortedData.filter((x) => value.data.includes(x.brandName));
      
      this.userSelected = [];
      this.resultSelected = [];
      this.currentStatusSelected=[];
    } else if (value.filterselected === filteringTypes.User) {
      this.filterIconUserActive = value.data.length == 0 ? false : true;
      this.filterIconBrandActive = false;
      this.filterIconResultActive = false;
      this.userSelected = value.data;
      this.filterRelatedArray =
        value.data.length == 0
          ? this.sortedData
          : this.sortedData.filter((x) => value.data.includes(x.firstName));
      this.BrandSelected = [];
      this.currentStatusSelected=[];
      this.resultSelected = [];
    } else if (value.filterselected === filteringTypes.Result) {
      this.filterIconResultActive = value.data.length == 0 ? false : true;
      this.filterIconBrandActive = false;
      this.filterIconUserActive = false;
      this.resultSelected = value.data;
      this.filterRelatedArray =
        value.data.length == 0
          ? this.sortedData
          : this.sortedData.filter((x) => value.data.includes(x.status));
      this.BrandSelected = [];
      this.currentStatusSelected=[];
      this.userSelected = [];
    }
    else if(value.filterselected === filteringTypes.currentStatus)
    {
      this.filterIconCurrentStatusActive = value.data.length == 0 ? false : true;
      this.filterIconBrandActive = false;
      this.filterIconUserActive = false;
      this.filterIconResultActive=false;
      this.currentStatusSelected = value.data;
      this.filterRelatedArray =value.data.length == 0? this.sortedData : this.sortedData1.filter((x) => value.data.includes(x.currentStatus));
      this.BrandSelected = [];
      this.userSelected = [];
      this.resultSelected=[];
    }
    this.dataSource.data = this.filterRelatedArray;
    this.filterBrandItems = false;
    this.filterUserItems = false;
    this.filterResultItems = false;
    this.filterCurrentStatus = false;
    if(fromRoute)
    {
      this.filterpageNumber = true;
    }
    else
    {
      this.filterpageNumber = false;

    }
  }
  hideFilter(cancelData) {
    if (cancelData.filterselected === filteringTypes.Brand) {

      this.dataSource.data = cancelData.data.length > 0 || this.filterIconBrandActive ?
        this.sortedData : this.filterRelatedArray;
      this.BrandSelected = [];
      this.filterBrandItems = false;
      this.filterIconBrandActive = false;
    } else if (cancelData.filterselected === filteringTypes.User) {

      this.dataSource.data = cancelData.data.length > 0 || this.filterIconUserActive ?
        this.sortedData : this.filterRelatedArray
      this.userSelected = [];
      this.filterUserItems = false;
      this.filterIconUserActive = false;
    } else if (cancelData.filterselected === filteringTypes.Result) {
      this.dataSource.data = cancelData.data.length > 0 || this.filterIconResultActive ?
        this.sortedData : this.filterRelatedArray
      this.resultSelected = [];
      this.filterResultItems = false;
      this.filterIconResultActive = false;
    }
    else if (cancelData.filterselected === filteringTypes.currentStatus) {
      this.dataSource.data = cancelData.data.length > 0 || this.filterIconCurrentStatusActive ?
        this.sortedData : this.filterRelatedArray
      this.currentStatusSelected = [];
      this.filterCurrentStatus = false;
      this.filterIconCurrentStatusActive = false;
    }
    if (!this.filterIconBrandActive &&
      !this.filterIconUserActive &&
      !this.filterIconResultActive&&
      !this.filterIconCurrentStatusActive) {
      this.filterRelatedArray = this.sortedData;
    }
  }

  awhUrlSent(url){
    window.open(url, '_blank');
  }

  clearFiltersWhenPackageCategoryChanged(){
    this.userSelected = [];
    this.currentStatusSelected = [];
    this.resultSelected = [];
    this.BrandSelected = [];
    this.filterUserItems = false;
    this.filterIconUserActive = false;
    this.filterCurrentStatus = false;
    this.filterIconCurrentStatusActive = false;
    this.filterResultItems = false;
    this.filterIconResultActive = false;
    this.filterBrandItems = false;
    this.filterIconBrandActive = false;
    this.filterpageNumber = false;
  }
}
